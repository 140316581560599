import 'react-app-polyfill/ie11';
import 'core-js/stable';
import axios from 'axios';
import qs from 'qs';



export const orderDocumentDownload = async (contentUrl) => {
  try {
    if (window.config.isApigee) {
      let tokenAPI = window.config.isDevDownload ? window.config.accessTokenDevUrl : window.config.accessTokenBetaUrl
      let token = await axios.post(tokenAPI,
        qs.stringify({
          'client_id': window.config.isDevDownload ? window.config["d3e13c296cf1fd10beca8bfa4e804bc3DEV"]?.split("&=")[1] : window.config["d3e13c296cf1fd10beca8bfa4e804bc3"]?.split("&=")[1],
          'client_secret': window.config.isDevDownload ? window.config["c4a66e40cbf929907d073437b8371f90DEV"]?.split("&=")[1] : window.config["c4a66e40cbf929907d073437b8371f90"]?.split("&=")[1],
          'grant_type': 'client_credentials'
        }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      contentUrl = JSON.parse(contentUrl)
      let config = {
        method: 'get',
        responseType: 'arraybuffer',
        url: window.config.isDevDownload ? window.config.documentDownloadDev : window.config.documentDownloadBeta,
        headers: {
          'Authorization': `Bearer ${token?.data?.access_token}`,
          "HON-Org-Id": contentUrl["HON-Org-Id"],
          "siteName": contentUrl["siteName"],
          "documentNumber": contentUrl["documentNumber"].toString(),
          "soldToNumber": contentUrl["soldToNumber"].toString(),
          "outputType": contentUrl["outputType"],
          "docOutputType": contentUrl["docOutputType"],
          "documentFlag": contentUrl["documentFlag"],
          "salesOrg": contentUrl["salesOrg"].toString(),
          "sourceSystem": contentUrl["sourceSystem"],
          "Accept": 'application/pdf'
        }
      };

      let response = await axios(config);

      if (!response.data.error) {
        return {
          status: 200,
          body: response.data
        };
      } else {
        return {
          status: response.status,
          body: response.statusText
        }
      }
    } else {
      let res;
      if (window.config.businessUnit === 'PMT-HPS') {
        const documentDownloadAPI = window.config.documentDownloadAPI;
        res = await axios.post(window.config.documentDownloadAPIUrl, JSON.parse(contentUrl), { responseType: 'arraybuffer', headers: { 'x-functions-key': documentDownloadAPI.split("&=")[1], 'Content-Type': 'application/json', Accept: 'application/pdf', } });
      }
      else {
        res = await axios.post(window.config.documentDownloadAPIUrl, JSON.parse(contentUrl), { responseType: 'arraybuffer', headers: { 'x-functions-key': window.config.documentDownloadAPIkey, 'Content-Type': 'application/json', Accept: 'application/pdf', } });
      }
      return res;
    }

  }
  catch (err) {
    //Code errors during failover scenario.
    return { "error": 'fetch failed.' }
  }

}
export default orderDocumentDownload;
