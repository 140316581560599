import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, { useState, useContext, useCallback } from 'react';
import ChatbotLogo from "../images/svgLatest/ChatbotLogo.svg";
import ChatbotLogo_HBT from "../images/svgLatest/ChatbotLogo_HBT.svg";
import { hooks } from "botframework-webchat-component";
import { AppContext } from "../util/appContext";
import Spinner from "./Spinner";
import Close from "../images/svg/Close.svg";
import Markdown from "markdown-to-jsx";
import StaticLabels from "../util/staticLabels.json";
import liveAgentStaticLabels from "../util/liveAgentStaticLabels.json";
import {setCookie, getCookie, eraseCookie} from '../util/cookieActions';
import HeaderLogo_HBT from "../images/svgLatest/HeaderLogo_HBT.svg";

var classNames = require("classnames");

const MyLink = ({ children, ...props }) => {
  return (
    <a style={{ fontSize: "13px" , textDecoration:'underline'}} {...props}>
      {children}
    </a>
  );
};


function GreetHBTUser() {
  const timeFormat = formatAMPM(new Date());
  if (timeFormat.ampm === 'AM') {
    return StaticLabels[window.config.language].GoodMorning;
  } else {
    if (timeFormat.hour >= 4 && timeFormat.hour <= 7) {
      return StaticLabels[window.config.language].GoodEvening;
    }
    if (timeFormat.hour >= 1 && timeFormat.hour <= 4) {
      return StaticLabels[window.config.language].GoodAfternoon;
    }

    if (timeFormat.hour === 12) {
      return StaticLabels[window.config.language].GoodAfternoon;
    }

    if (timeFormat.hour > 7 && timeFormat.hour) {
      return StaticLabels[window.config.language].GoodEvening;
    }
  }
}

function formatAMPM(date) {
  var hours = date.getHours();
  // var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0'+minutes : minutes;
  // var strTime = hours + ':' + minutes + ' ' + ampm;
  return { hour: hours, ampm: ampm };
}


const WelcomeCardHBT = ({ content, contentType, handleModalChange, contentPrivacy, handleMaximizeChange, handleCloseModalPopup }) => {
  const { useSendMessage, useSendEvent } = hooks;
  const context = useContext(AppContext);
  const sendMessage = useSendMessage();
  const sendEvent = useSendEvent();
  const texts = content.text.split("/");

  const HBTPages = ["login", "SecureCustomerPortalRegisterPage", "searchEmpty", "searchGrid", "productDetails", "multiStepCheckoutSummaryPage", "orders", "cartPage", "quickCart",
                  "importCSVSavedCartPage", "quoteEditPage", "my-quotes", "quote-detail", "address-book", "rmarequestpage", "customerDashboardPage", "homepage", "myProfile",
                  "custServiceRequest", "techKnowledgeCenter", "techSupportRequest", "techPubs", "productDownloads"]

  var homeMenuoptions = classNames({
    customscrollbar : true,
    homeContainerButtonsHBT : true
  });

  const handleButtonClick = (selectedOption) => {
    let welcomecardBotDiv = document.getElementsByClassName('welcomecardBotDivHeight');
    if (welcomecardBotDiv.length > 0 ) {
      welcomecardBotDiv[0].style.height = 'auto';
    }
    
    context.setOptionSelected(true);
    context.setShowHeaderFooter(true);
    context.setShowSendBox(true);
    context.setEnableScroll(true);
    context.setHideStickyMenu(false);
    context.setMoreOptionsClick(false);
    setCookie(window.config.businessUnit + '-Conversation',true);
    sendMessage(selectedOption);
    
  }

  const handleHBTNoThanks = useCallback(event => {
    sendEvent('SalesPagesNoThanks', { clickedNoThanks : true });
    handleCloseModalPopup();
    handleMaximizeChange();
  },
  [sendEvent, handleCloseModalPopup, handleMaximizeChange]
);

const handleHBTCrossIconClick = useCallback(event => {
  if(HBTPages.includes(window.config.pageTitle)) {
    sendEvent('SalesPagesNoThanks', { clickedNoThanks : false });
  }
  handleCloseModalPopup();
  handleMaximizeChange();
},
[sendEvent, handleCloseModalPopup, handleMaximizeChange,HBTPages]
);


var headerClass = classNames({
  headerMax: true,
  header: true
});
var sendBoxDisableClass = classNames({
  'send-box-input' : true,
  sendBoxInputHBT : true,
  disableSendBox : true
});

  const liveAgentUnavailableMsg = () => {
    const lacUnavailable = content.buttons.some((buttonText, index) => (
      buttonText.toLowerCase() === liveAgentStaticLabels[window.config.language].LiveAgentUnavailable));
     if(lacUnavailable) {
      return (
        <div className='HBTLiveagentTextAlign'>
          {content.lacOperationalHoursMsg && <p className='HBTLiveagentFont'>{content.lacOperationalHoursMsg}</p>}
          {content.lacHolidaysMsg && <p className='HBTLiveagentFont'>{content.lacHolidaysMsg}</p>}
        </div>
      )
   }
  }

  const languageEnabled = window.config.language === 'en' || window.config.language === 'de' || window.config.language === 'fr' || window.config.language === 'it' || window.config.language === 'es' || window.config.language === 'nl'

  return (
    context.showSpinnerWheel === false ?
      !getCookie(window.config.businessUnit + '-Conversation') &&
      (context.optionSelected === false &&
        <div style={{padding : '0px', marginTop : '-13px'}}>
          <div className={headerClass}>
            <div className="ask-ellie">
              <img
                className="launcherIconAvatar"
                alt="miniAvatar"
                src={HeaderLogo_HBT}
              /> 
              <h4 style={{fontWeight : '600'}}>Honeywell Assist</h4>
            </div>
            <ul>
              <li style={{cursor: 'pointer'}}>
              <img alt="Close"   title="Close Bot" src={Close} onClick={handleHBTCrossIconClick}></img>
              </li>
            </ul>
        </div>
        <div className="homeContainer">
          <div className="homeContainerFixed">
              <div style={{marginBottom : '1rem'}} className="botTextBox conversationTextBox conversationTextBoxWelcomeMessage">
                <div className="conv-text welcomeMessage">
                {window.config.isNewPrivacy?<><p>{texts[0].replace("[GreetDCAUser]",window.config.language ? StaticLabels[window.config.language].welcomeShowText:StaticLabels["en"].welcomeShowText)+" "}{window.config.language ? StaticLabels[window.config.language].welcomeText:StaticLabels["en"].welcomeText}</p>
                <p className="margin_5">{window.config.language ? StaticLabels[window.config.language].chooseWelcome:StaticLabels["en"].chooseWelcome}</p></>:<p>{texts[0].replace("[GreetDCAUser]",`${GreetHBTUser()}`)}</p>}
                {window.config.isNewPrivacy?null:<p>{texts[1]}<span>{texts[2]}</span> </p>}
                <p>{texts[3]}</p>
                </div>
              </div>
              {/* <div style= {{marginBottom : '1rem'}} className="botTextBox conversationTextBox">  
                <div className="conv-text">
                {contentPrivacy && <p className="contentPolicyHBT"><Markdown
                options={{
                  overrides: {
                    forceBlock: true,
                    forceInline: false,
                    a: {
                      component: MyLink,
                      target: "_blank",
                      props: {
                        className: "markdown-link",
                        target: "_blank"
                      }
                    }
                  }
                }}
              >{contentPrivacy}</Markdown></p>}
                 
                </div>
                </div> */}
                {content.salesMessage && <div style= {{marginBottom : '1rem'}} className="botTextBox conversationTextBox conversationTextBoxWelcomeMessage">  
                <div className="conv-text">
                   <p className='HBTLiveagentMargin'>{content.salesMessage}</p> 
                </div>
                </div>}
          </div>
           
            {languageEnabled ? 
            <div className={homeMenuoptions}>
              {content.buttons.map((buttonText, index) => (
                buttonText.toLowerCase() === liveAgentStaticLabels[window.config.language].NoThanks && window.config.preLoginSalesPages.includes(window.config.pageTitle) ?
                <button key={index} className="btn-secondary WelcomeButton"  onClick={handleHBTNoThanks}>{buttonText} </button>
                :
                  <button key={index} className="btn-secondary WelcomeButton LiveAgentUnavailable" disabled={buttonText.toLowerCase() === liveAgentStaticLabels[window.config.language].LiveAgentUnavailable.toLowerCase() ? true : false} onClick={() => handleButtonClick(buttonText)}>{buttonText} </button> ))}
            </div> :
            <div className={homeMenuoptions}>
            {content.buttons.map((buttonText, index) => (
              <button key={index} className="btn-secondary WelcomeButton" onClick={() => handleButtonClick(buttonText)}>{buttonText} </button>))}
          </div>}
          
          {liveAgentUnavailableMsg()}
            <div>  
          </div>
        </div>
        </div>
      
      ) : (
      <Spinner
          isMaximised={true}
          showSpinner={true}
          handleModalChange={handleModalChange}
        />
      )
  )
}

export default WelcomeCardHBT;