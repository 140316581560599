import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useState, useCallback, useContext, useEffect, useRef } from "react";
import MainWebChat from "./components/MainWebchat";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import initCWASSO from "./util/initCWASSO";
import initCWASSOUOP from "./util/initCWASSOUOP";
import initCWASSOHBT from "./util/initCWASSOHBT";
import initCWASSOPMTAMRC from "./util/initCWASSOPMT_AMRC";
import initCWASSOPMTAMRC_SSO from "./util/initCWASSOPMTAMRC_SSO";
import setCWACookies from "./util/setCWACookies";
import getCWACookies from "./util/getCWACookies";
import { createDirectLine } from "botframework-webchat";
import ErrorHandler from "./components/ErrorHandler";
import { getToken, getRefreshToken } from "./util/httprequest";
import { getAppInsights } from "./TelemetryService";
import TelemetryProvider from "./telemetry-provider";
import styleSet from "./util/chatBotCustomStyleSet";
import { AppContext } from "./util/appContext";
import { v4 as uuidv4 } from "uuid";
import {setCookie, getCookie, eraseCookie} from './util/cookieActions';
import pmtHpsNonprodConfig from './config/pmt_hps_config.json';
import pmtHpsProdConfig from './config/pmt_hps_prod_config.json';
import spsEcommNonprodConfig from './config/sps_ecomm_config.json';
import spsEcommProdConfig from './config/sps_ecomm_prod_config.json';

//Main entry component for connecting to Bot.
export default () => {
  // let appInsights = null;
  const [isMaximised, setMaximizeflag] = useState(false);
  const [directLine, setDirectLine] = useState();
  const [store, setStore] = useState();
  const [isLiveAgent, setLiveAgent] = useState(false);
  const [showSpinner, setSpinner] = useState(true);
  const [isOpenModal, showModal] = useState(false);
  const [isSurveyOpen, showSurvey] = useState(false);
  const [errorOccured, showErrorPage] = useState({
    isErrorOccured: false,
    message: ""
  });
  // const [refresh, setRefresh] = useState(false);
  const [userLanguage, setUserLanguage] = useState("");
  const [appInsights, setAppInsights] = useState(null);
  const [isSurveySubmitted, setSurveyFlag] = useState(false);
  const [headerIconsDisplay, setHeaderIconsDisplay] = useState(true);
  const [showTimerBox, setShowTimerBox] = useState(false);
  const [callbackFormUrl,setCallbackFormUrl] = useState("");
  const [surveyLink, setSurveyLink] = useState("");
  const [satmatrixSurvey, setSatmatrixSurvey] = useState(false);
  const [satmatrixSurveyIFrame, setSatmatrixSurveyIFrame] = useState(false);
  const [isAgentInitiated, setAgentInitiated] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [receiverOrigin, setreceiverOrigin] = useState('*');
  const context = useContext(AppContext);
  const surveyPageShow = useRef(false);
  const resizeWindowScreen_Latest=()=>{
    const rootMax = document.querySelector('.root-max');
    // Get window width
    if(rootMax){
    document.getElementById("root").removeAttribute("style");
    const windowWidth = window.screen.width;
    console.log(windowWidth,"..windowWidth")
    // Apply styles based on window width
    if (windowWidth >= 1601) {
      rootMax.style.width = '425px';
      rootMax.style.height = '600px';
    } else if (windowWidth <= 1600) {
      rootMax.style.width = '375px';
      rootMax.style.height ='500px';
    }
    }
    else{
    window.parent.postMessage({key:'minimise', launcherIcon: false}, window.config.pageOrigin);
    }
  }
  document.addEventListener("DOMContentLoaded", function () {
    console.log("111");
    //applyStyles();
  });
  document.addEventListener("beforeunload", function() {
    sessionStorage.removeItem("cwaSSOToken");
  });

  window.addEventListener("load", event => {
    sessionStorage.setItem('notification',false);
    sessionStorage.removeItem("chatbotActive");
    if(event.origin !== window.config.pageOrigin) 
      return; 
  });
  
  useEffect(() => {
    document.addEventListener("marketoformsubmit", event => {
        store.dispatch({
          type: "WEB_CHAT/SEND_POST_BACK",
          payload: {
              value: {
                  processName: "marketoFormSubmitted"
              }
          }
        });
    });
    let browserName = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1: return "Edge";
        case agent.indexOf("edg/") > -1: return "Edge";
        case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
        case agent.indexOf("trident") > -1: return "IE";
        case agent.indexOf("firefox") > -1: return "Firefox";
        case agent.indexOf("safari") > -1: return "Safari";
        default: return "other";
      }
    })(window.navigator.userAgent.toLowerCase());

    window.parent.postMessage({key:'init_cwa_sso', origin: window.location.origin}, receiverOrigin);
    window.onmessage = async (e) => {
      const data = JSON.parse(JSON.stringify(e.data));

      // context.setPageTitleCookie(data.pageTitle);
      // context.setPageLanguage(data.pageLanguage);
      // context.setCountrySelectedOnPortal(data.countrySelectedOnPortal);

      switch (data.key) {
        case 'init_cwa_sso':
          localStorage.setItem("pageUrl", data.pageUrl);

          let SBG = '';
          let localConfig = '';
          let isAEM = false;

          switch (data.value) {
            case 'https://accstorefront.ciq3kgmonc-honeywell1-s1-public.model-t.cc.commerce.ondemand.com':
            case 'https://buildings.stage.honeywell.com':
            case 'https://buildings.stage3.honeywell.com':
            case 'https://buildings.stage1.honeywell.com':
            case 'https://buildings.honeywell.com':
            case 'https://dpublish-hbt.aws.aem.honeywell.com':
            case 'https://qpublish-hbt.aws.aem.honeywell.com':
            case 'https://buildingsbt.stage.honeywell.com':
            case 'https://buildingsbt.baseline.uat.honeywell.com':
              SBG = 'HBT';
              break;
            case 'https://dcaspsext-honeywellaidc.cs90.force.com':
            case 'https://support.honeywellaidc.com':
            case 'https://sfq-honeywellaidc.cs63.force.com':
              SBG = 'SPS_TS';
              break;
            case 'https://qa-niagara.cs122.force.com':
            case 'https://niagara--qa.sandbox.my.site.com':
            case 'https://www.niagara-community.com':
              SBG = 'HCE_TRIDIUM';
              break;
            case 'https://lab.stage.honeywell.com':
            case 'https://lab.honeywell.com':
            case 'https://qpublish-rc.aws.aem.honeywell.com':
              SBG = 'PMT_AMRC';
              break;
            case 'https://uop.stage.honeywell.com':
            case 'https://uop.honeywell.com':
            case 'https://qpublish-uop.aws.aem.honeywell.com':
              SBG = 'PMT_UOP';
              if(data.value.includes('/shop')){
                isAEM = false
              }else {
                isAEM = true ;
              }
              break;
            case 'https://qpublish-process.aws.aem.honeywell.com':
            case 'https://process.stage.honeywell.com':
              SBG = 'PMT_HPS';
              localConfig = pmtHpsNonprodConfig;
              break;
            case 'https://process.honeywell.com':
              SBG = 'PMT_HPS';
              localConfig = pmtHpsProdConfig;
              break;
            case 'https://advancedmaterials.stage.honeywell.com':
            case 'https://advancedmaterials.honeywell.com':
              SBG = 'PMT_ADM';
              if(data.value.includes('/shop')){
                isAEM = false
              }else {
                isAEM = true ;
              }
              break;
            case 'https://productivity.honeywell.com':
              SBG = 'SPS_PPR';
              break;
            case 'https://safety.honeywell.com':
              SBG = 'SPS_HIS';
              break;
            case 'https://aerospacebt.stage.honeywell.com':
            case 'https://aerospace.honeywell.com':
            case 'https://dpublish-aerospacebt.aws.aem.honeywell.com':
            case 'https://qpublish-aerospacebt.aws.aem.honeywell.com':
            case 'https://aerospace2.honeywell.com':
            case 'https://qaerospace2.honeywell.com':
              SBG = 'AERO';
              break;
            case 'https://stage.honeywellforge.ai':
            case 'https://qa.honeywellforge.ai':
            case 'https://www.honeywellforge.ai':
              SBG = 'HCE_MARKETING';
              break;
            case 'https://dcaspsgdm-honeywell-sps.cs29.force.com':
            case 'https://honspsqa-honeywell-sps.cs215.force.com':
            case 'https://sps-support.honeywell.com':
              SBG = 'SPS_GDM';
              break;
            case 'https://sps-stage2.honeywell.com':
            case 'https://stage-sps.honeywell.com':
              SBG = 'SPS_ECOMM';
              localConfig = spsEcommNonprodConfig;
              break;
            case 'https://qpublish-sps.aws.aem.honeywell.com':
              SBG = 'SPS_LMT';
              break;
            case 'https://corp-cx-da-nondprod-portal.azurewebsites.net':
              let isFAQ2_0,showGenAIResponse;
              if(data.pageUrl.startsWith('https://corp-cx-da-nondprod-portal.azurewebsites.net/newDCAAero')){
                SBG = 'AERO';
                isFAQ2_0 = true;
               }
               else if(data.pageUrl.startsWith('https://corp-cx-da-nondprod-portal.azurewebsites.net/apchatbot')){
                SBG = 'HBT';
                isFAQ2_0 = true;
               }
               else if(data.pageUrl.startsWith('https://corp-cx-da-nondprod-portal.azurewebsites.net/faq2.0HPS')){
                  SBG = 'PMT_HPS';
                  localConfig = pmtHpsNonprodConfig;
                  isFAQ2_0 = true;
                 
                }
                else if(data.pageUrl.startsWith('https://corp-cx-da-nondprod-portal.azurewebsites.net/faq2.0AM')){
                  SBG = 'PMT_ADM';
                 // localConfig = pmtHpsNonprodConfig;
                  isFAQ2_0 = true;
                 
                }
                else if(data.pageUrl.startsWith('https://corp-cx-da-nondprod-portal.azurewebsites.net/faq2.0RC')){
                  SBG = 'PMT_AMRC';
                  //localConfig = pmtHpsNonprodConfig;
                  isFAQ2_0 = true;
                 
                }
                else if(data.pageUrl.startsWith('https://corp-cx-da-nondprod-portal.azurewebsites.net/faq2.0Aero')){
                  SBG = 'AERO';
                 // localConfig = pmtHpsNonprodConfig;
                  isFAQ2_0 = true;
                 
                }else{
                  SBG = 'SPS_ECOMM';
                  isFAQ2_0 = true
                  showGenAIResponse = data.pageUrl.startsWith('https://corp-cx-da-nondprod-portal.azurewebsites.net/faq2.0GenAIResponse')  ? true : false
                  localConfig = spsEcommNonprodConfig;
                 
                }
                break;
  
            case 'https://sps.honeywell.com':
              SBG = data.pageUrl.startsWith('https://sps.honeywell.com/us/en')  ? 'SPS_LMT' : 
                    data.pageUrl.startsWith('https://sps.honeywell.com/partners/safety') ? 'SPS_HIS' : 'SPS_ECOMM';
              if(SBG === 'SPS_ECOMM') {
                localConfig = spsEcommProdConfig;
              }
              break;
            case 'https://sps.stage.honeywell.com':
            case 'https://qpublish-automation.aws.aem.honeywell.com/':
            case 'https://automation.stage.honeywell.com/':
                SBG = data.pageUrl.startsWith('https://sps.stage.honeywell.com/us/en') || data.pageUrl.startsWith('https://qpublish-automation.aws.aem.honeywell.com/') || data.pageUrl.startsWith('https://automation.stage.honeywell.com/')   ? 'SPS_LMT' : 'SPS_HIS';
                break;
            case 'https://qapsupplierportal.honeywell.com':
                SBG = 'AP';
                break;
            default:
              SBG = '';
              break;
          }

          if (SBG === 'PMT_HPS' || SBG === 'SPS_ECOMM') {
            window.config = localConfig;
          } else {
            const response = await fetch(`${window.location.origin}/config.json?SBG=${SBG}`, {
              'headers': {
                "Origin": data.value,
              }
            });
            window.config = await response.json();
          }

          window.config.isAEM = isAEM;

          if(window.config.isNewSSO){
            window.config.isNewSSO = (SBG =='PMT_UOP'|| SBG=='PMT_ADM') ? isAEM : window.config.isNewSSO //for Adm and UOP non aem pages should use old authentication.
          }
          window.config.showTypingIndicator = true;
          window.config.isDefaultWorkflowClicked = false;
          window.config.elementsCount = 0;
          //window.config.dp = data.dp;
          // window.config.pageTitle = data.pageTitle;
          // window.config.language = data.pageLanguage;
          // window.config.countrySelectedOnPortal = data.countrySelectedOnPortal;
          //context.setCountrySelectedOnPortal(data.countrySelectedOnPortal);
          window.config.portalPageUrl = data.pageUrl;
          window.config.sessionRefreshed = data.sessionRefreshed;
          window.config.pageOrigin = data.value;
          window.config.browserName = browserName;
          setIsDisplay(true);
          if(window.config.isNewSSO) {
            window.parent.postMessage({ key: 'display_chatbot', businessUnit:window.config.businessUnit,cookieUrl:window.config.cookieUrl,cookieKey:window.config["3469726563746c696e650k08"].split("&=")[1],jwtTokenName: window.config.businessUnit === "SPS-ECOMM" || window.config.isChina ? window.config.jwtTokenNameHybris:window.config.jwtTokenName,pageOrigin:window.config.pageOrigin }, window.config.pageOrigin);
           // setCWACookies("cwaSSOToken", "Anonymous", 100000);
          }
          else if(window.config.businessUnit === 'PMT-AMRC') {
            if(window.config.pageOrigin.includes('aem')) {
              window.config.clientId = "Client_d45f83004rrl";
              initCWASSOPMTAMRC_SSO();
            }
            else {
              initCWASSOPMTAMRC();
            }
          }
          else if(window.config.businessUnit === 'HCE-TRIDIUM' || window.config.businessUnit === 'SPS-PSS' || window.config.businessUnit === 'SPS-GDM') {
            window.parent.postMessage({ key: 'display_chatbot', businessUnit:window.config.businessUnit,cookieUrl:window.config.cookieUrl,cookieKey:window.config["3469726563746c696e650k08"].split("&=")[1],jwtTokenName: window.config.businessUnit === "SPS-ECOMM" || window.config.isChina ? window.config.jwtTokenNameHybris:window.config.jwtTokenName,pageOrigin:window.config.pageOrigin }, window.config.pageOrigin);
            //setCWACookies("cwaSSOToken", "Anonymous", 100000);
          }
          else if(window.config.businessUnit === 'SPS-ECOMM') {
          initCWASSO(); 
          }
          else if(window.config.businessUnit === 'PMT-UOP') {
            initCWASSOUOP();
          } else if(window.config.businessUnit === 'HBT-GCEGBT') {
            window.parent.postMessage({ key: 'display_chatbot', businessUnit:window.config.businessUnit,cookieUrl:window.config.cookieUrl,cookieKey:window.config["3469726563746c696e650k08"].split("&=")[1],jwtTokenName: window.config.businessUnit === "SPS-ECOMM" || window.config.isChina ? window.config.jwtTokenNameHybris:window.config.jwtTokenName,pageOrigin:window.config.pageOrigin}, window.config.pageOrigin);
            //setCWACookies("cwaSSOToken", "Anonymous", 100000);
          }
          else {
            initCWASSO();
          }
          break;
       case 'cwa_sso_token_set':
          if (!data.value) {
            data.value = 'Anonymous';
          }
          setCWACookies('cwaSSOToken', data.value, 100000);
          window.config.pageTitle = data.pageTitle;
          window.config.language = data.pageLanguage;
          window.config.countrySelectedOnPortal = data.countrySelectedOnPortal;
          window.config.portalPageUrl = data.pageUrl;
          window.config.sessionRefreshed = data.sessionRefreshed;
          break;
       case 'cwa_cookies_http':
            console.log(data,"..data");
            if (!data.value) {
              data.value = 'Anonymous';
            }
            setCWACookies('cwaSSOToken', data.value, 100000);
            window.config.pageTitle = data.pageTitle;
            window.config.language = data.pageLanguage;
            window.config.countrySelectedOnPortal = data.countrySelectedOnPortal;
            window.config.portalPageUrl = data.pageUrl;
            window.config.sessionRefreshed = data.sessionRefreshed;
            window.config.b2bunit = data.b2bunit;
            window.config.dp = data.dp;
            context.setPageTitleCookie(data.pageTitle);
            context.setPageLanguage(data.pageLanguage);
            context.setCountrySelectedOnPortal(data.countrySelectedOnPortal)
            break;
       default:
          break;
      }
    };
    sessionStorage.removeItem(window.config.businessUnit + '-SatmatrixSurveyOpen');
    const interval = setInterval(() => {
      if (getCookie(window.config.businessUnit + '-BotLastResponseTime') && document.getElementById("root").classList.contains("root-max")) {
        const botLastResponseTime = new Date(getCookie(window.config.businessUnit + '-BotLastResponseTime'));
        const currentTime = new Date();
        const diff = Math.abs(currentTime.getTime() - botLastResponseTime.getTime()) / 60000;
        if (diff > 15) {
          showModal(true);
          context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: true});
          removeDLCookies("sessiontimeout");
        }
      }
    }, 10 * 1000);
    return () => { clearInterval(interval); };
  }, []);

  const accessToken = getCWACookies("cwaSSOToken");
  
  function setDirectLineToken() {
    if(window.config.isNewPrivacy==true){
      if(getCookie(window.config.businessUnit + '-isPrivacyAccepted')){
      setDirectLineTokenCall();
      }
     }
     else{
      setDirectLineTokenCall();
     }
  }
  function setDirectLineTokenCall() {
      if(getCookie(window.config.businessUnit + '-Token') && 
           getCookie(window.config.businessUnit + '-Conversation') && 
           getCookie(window.config.businessUnit + '-AuthToken') && 
           getCWACookies("cwaSSOToken") && 
           getCookie(window.config.businessUnit + '-AuthToken').length === getCWACookies("cwaSSOToken").length
           ) {
          if (window.config.businessUnit === 'HBT-GCEGBT' || window.config.businessUnit === 'SPS-ECOMM') {
            if (getCookie(window.config.businessUnit + '-Language') &&
              getCookie(window.config.businessUnit + '-Language') === window.config.language) {
              let token = getCookie(window.config.businessUnit + '-Token');
              setDirectLine(createDirectLine({ token }));
              setCookie(window.config.businessUnit + '-OldCoversation', true);
              setCookie(window.config.businessUnit + '-botMaximiseTime',new Date().getTime());
           }
          }
          else {
            let token = getCookie(window.config.businessUnit + '-Token');       
            setDirectLine(createDirectLine({ token }));
            if (window.config.isPersisted) {
              setCookie(window.config.businessUnit + '-OldCoversation', true);
              setCookie(window.config.businessUnit + '-botMaximiseTime',new Date().getTime());
            }
          }
          
      } 
      else{
        removeDLCookies(false);
        getDirectLineToken()
          .then(result => {
          const token = result.token;
          setDirectLine(createDirectLine({token}));
          if(window.config.businessUnit === 'HBT-GCEGBT' || window.config.isPersisted) {
            setCookie(window.config.businessUnit + '-Token',token);
            setCookie(window.config.businessUnit + '-AuthToken', getCWACookies("cwaSSOToken"));
            setCookie(window.config.businessUnit + '-OldCoversation',false);
            setCookie(window.config.businessUnit + '-Language', window.config.language);
          }
      }).catch(error => {
        if (appInsights) {
          appInsights.trackTrace({
            message: "Exceptions",
            properties: {
              name: "Direct line error",
              message: error.message,
              stack: error.stack
            }
          });
        }
      });
    }
    refreshDLToken();
  }

  const removeDLCookies = (timeout) => {
    eraseCookie(window.config.businessUnit + '-Token');
    eraseCookie(window.config.businessUnit + '-Conversation');
    eraseCookie(window.config.businessUnit + '-botMaximise');
    eraseCookie(window.config.businessUnit + '-AuthToken');
    eraseCookie(window.config.businessUnit + '-OldCoversation');
    eraseCookie(window.config.businessUnit + '-Language');
    eraseCookie(window.config.businessUnit + '-EnableSendBox');
    eraseCookie(window.config.businessUnit + '-BotLastResponseTime');
    eraseCookie(window.config.businessUnit + '-botMaximiseTime');
    eraseCookie(window.config.businessUnit + '-CheckOrderLob');
    eraseCookie(window.config.businessUnit + '-CheckOriginalId');
    eraseCookie(window.config.businessUnit + '-WebPopup');
    if(timeout && timeout=="sessiontimeout"){
      if(getCookie(window.config.businessUnit + '-isPrivacyAccepted') && window.config.isNewPrivacy==true) {
        eraseCookie(window.config.businessUnit + '-isPrivacyAccepted');
      }
    }
  };

  const refreshDLToken = () => {
    setInterval(() => {
      if (getCookie(window.config.businessUnit + '-Token')) {
        getRefreshDirectLineToken();
      }
    }, 20*60*1000);
  };

  const handleSpinnerChange = useCallback(() => {
    setSpinner(!showSpinner);
  }, [showSpinner, setSpinner]);

  const handleSurveyCardChange = useCallback(
    event => {
      if (isSurveyOpen) {
        setSurveyFlag(!isSurveySubmitted);
        // setDirectLine(null);
        // setStore(null);
        context.setLiveAgent(false);
      }
      showSurvey(!isSurveyOpen);
    },
    [
      isSurveySubmitted,
      isSurveyOpen,
      setSurveyFlag,
      showSurvey,
      // setDirectLine,
      // setStore
      setLiveAgent
    ]
  );

  const handleRefreshModalPopup = useCallback(event => {
      if (isSurveyOpen) {
        showSurvey(false);
      }
      showModal(false);
      showErrorPage({ isErrorOccured: false, message: "" });
      setSpinner(true);
      context.setLiveAgent(false);
      setAgentInitiated(false);
      context.setShowSendBox(false);
      context.setDisableSendBox(true);
      context.setEnableSendBoxHBT(false);
      setShowTimerBox(false);
      context.setShowHeaderFooter(false);
      context.setEnableScroll(false);
      setUserLanguage("");
      context.setOptionSelected(false);
      context.setHideStickyMenu(false);
      context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: false });
  },
  [setDirectLineToken, setUserLanguage, setShowTimerBox, setAgentInitiated, setLiveAgent]
  );
  
  const closeLiveAgentProps = () => {
    setShowTimerBox(false);
    context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: false });
    context.setUsersCount(0);
  }

  const handleMaximizeChange = useCallback(() => {
    if (!directLine) {
      // We will load DirectLineJS asynchronously on first render.
      setDirectLineToken();
    }
    if(!isMaximised) {
      sessionStorage.setItem("chatbotActive", true);
      sessionStorage.setItem('notification',true);

      if(window.config.businessUnit === "HCE-MARKETING") {
        if(!context.minimizeBot) {
            store.dispatch({
              type: "WEB_CHAT/SEND_POST_BACK",
              payload: {
                  value: {
                      processName: "initiateMarketingWorkflow",
                      userId: localStorage.getItem("DCAUserId"),
                      startingMessage: context.startingMessage,
                      workflowId: context.workflowId,
                      pageRuleId: context.pageRuleId,
                      pageId: context.pageId,
                      priority: context.priority,
                      exceptionErrorFound: false,
                      sendWelcomeCard: window.config.dp == "true"
                  }
              }
          });
          setSpinner(true);
        }
      } 
      
     else if(context.showHeaderFooter !== true){
        context.setShowHeaderFooter(false);
        context.setShowSendBox(false);
      }
      //On Survey submit we are refreshing the bot conversation.
      if (isSurveySubmitted) {
        if(window.config.businessUnit !== "HCE-MARKETING") { 
          handleSpinnerChange();
          setSurveyFlag(!isSurveySubmitted);
          removeDLCookies(false);
          setDirectLine(null);
          setStore(null);
          setDirectLineToken();
        }
        setHeaderIconsDisplay(true);
        setUserLanguage("");
        closeLiveAgentProps();
        context.setDisableSendBox(true);
        context.setEnableSendBoxHBT(false);
      }
      document.getElementById("root").classList.add("root-max");
      document.getElementById("root").classList.remove("root-min");
      document.getElementById("main").classList.remove("main-min");
      document.getElementById("main").classList.add("main-max");
      resizeWindowScreen_Latest();
      context.setMenuOpen(false);
      window.parent.postMessage({key:'maximise'}, window.config.pageOrigin);
      setTimeout(() => {
        if (document.getElementById("web-chat") && !isMaximised) {
          document.getElementById(
            "web-chat"
          ).scrollTop = document.getElementById("web-chat").scrollHeight;
        }
      }, 0);
    } else {
      // context.setShowHeaderFooter(false);
      // context.setEnableScroll(false);
      context.setMenuOpen(false);
      sessionStorage.setItem('notification',false);
      sessionStorage.removeItem("chatbotActive");
      document.getElementById("root").removeAttribute("style");
      document.getElementById("root").classList.remove("root-max");
      document.getElementById("main").classList.remove("main-max");
      document.getElementById("main").classList.add("main-min");
      window.parent.postMessage({key:'minimise', launcherIcon: false}, window.config.pageOrigin);
     }

    setMaximizeflag(!isMaximised);
    if(window.config.businessUnit === 'HBT-GCEGBT' || window.config.isPersisted) {
    setCookie(window.config.businessUnit + '-botMaximise',!isMaximised);
    }
  }, [
    isMaximised,
    showSpinner,
    isSurveySubmitted,
    setMaximizeflag,
    handleSpinnerChange,
    setSurveyFlag,
    closeLiveAgentProps
  ]);

  const handleCloseModalPopup = useCallback(event => {
    setSurveyFlag(!isSurveySubmitted);
    setHeaderIconsDisplay(false);
    context.setLiveAgent(false);
    context.setOptionSelected(false);
    context.setShowGoBackbutton(true);
    context.setHideStickyMenu(false);
    context.setShowHeaderFooter(false);
    context.setEnableScroll(false);
    sessionStorage.removeItem("chatbotActive");
    sessionStorage.setItem('notification',false);
    context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: false });
    showModal(false);
    context.setIsAccountChanged(false);
    removeDLCookies(false);

    if(window.config.businessUnit === "HCE-MARKETING") {
        window.config.isDefaultWorkflow = false;
        window.config.isWorkflowTriggered = false;
        window.config.isWorkflowClicked = false;
        window.config.showTypingIndicator = true;
        window.config.eventFrom = "DCA";

        context.setWorkflowId(null);
        context.setPageRuleId(null);
        context.setPageId(null);
        context.setPriority(null);
        setDirectLine(null);
        setStore(null);
        setDirectLineToken();
    }
  }, [handleMaximizeChange, setSurveyFlag]);

  const handleCloseBotInTabs = useCallback(() => {
      context.setMenuOpen(false);
      sessionStorage.setItem('notification',false);
      sessionStorage.removeItem("chatbotActive");
      document.getElementById("root").removeAttribute("style");
      document.getElementById("root").classList.remove("root-max");
      document.getElementById("main").classList.remove("main-max");
      document.getElementById("main").classList.add("main-min");
      window.parent.postMessage({key:'minimise', launcherIcon: false}, window.config.pageOrigin);
      setMaximizeflag(false);
      window.history.go(0);
  }, [
    isMaximised,
    isSurveySubmitted
  ]);

  const handleModalChange = useCallback(
    actionItem => event => {
        context.setMenuOpen(false);
      // sendMessage("GetSurveyLink");
      if (actionItem.target === "refresh") {
        if(actionItem.backMenu) {
          context.setMenuOpen(true);
        }
        if (actionItem.value) {
          if (actionItem.value === "Yes") {
            if (isSurveyOpen) {
              showSurvey(false);
            }
            removeDLCookies(false);
            setDirectLine(null);
            setStore(null);
            showErrorPage({ isErrorOccured: false, message: "" });
            setSpinner(true);
            context.setLiveAgent(false);
            setAgentInitiated(false);
            context.setShowSendBox(false);
            context.setDisableSendBox(true);
            context.setEnableSendBoxHBT(false);
            setShowTimerBox(false);
            context.setShowHeaderFooter(false);
            context.setEnableScroll(false);
            setDirectLineToken();
            setUserLanguage("");
            context.setOptionSelected(false);
            context.setHideStickyMenu(false);
          }
          context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: false });
        } else {
          context.setModalProps({ refresh: true, close: false, quitAgentQueue: false, busyNotification: false });
        }
      }

      if (actionItem.target === "closeWithSurvey") {
            handleSurveyCardChange();
            setHeaderIconsDisplay(false);
           context.setShowHeaderFooter(false);
           context.setEnableScroll(false);
      }

        if (actionItem.target === "close") {
          if (actionItem.value) {
            if (store != null) {
              store.dispatch({
                type: "WEB_CHAT/SEND_EVENT",
                payload: {
                  name: "feedbackCancelButton"
                }
              });
            }
            context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: false });
          }
          else {
            context.setModalProps({ refresh: false, close: true, quitAgentQueue: false, busyNotification: false });
          }
      }

      if(actionItem.target === "closeLaunchIcon"){
        handleMaximizeChange();
        handleCloseModalPopup();
      }

      if (actionItem.target === "QuitandCreateTicket") {
        // if (actionItem.value) {
        //   if (actionItem.value === "Yes") {
            cancelSession();
            handleSurveyCardChange();
            window.open(callbackFormUrl, "_blank");
        //   } else {
        //     context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: false });
        //   }
        // } else {
        //   context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: true });
        // }
      }

      if (actionItem.target === "QuitAgentQueue") {
        // if (actionItem.value) {
        //   if (actionItem.value === "Yes") {
            cancelSession();
            handleSurveyCardChange();
            setHeaderIconsDisplay(false);
            context.setShowHeaderFooter(false);
            context.setShowGoBackbutton(false);
            context.setEnableScroll(false);
            window.open(callbackFormUrl, "_blank");
        //   } else {
        //     context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: false });
        //   }
        // } else {
        //   context.setModalProps({ refresh: false, close: false, quitAgentQueue: true, busyNotification: false });
        // }
      }

      if(actionItem.target === "shutdown"){
        setSurveyLink("");
        setSatmatrixSurvey(false);
        setSatmatrixSurveyIFrame(false);
        handleSurveyCardChange();
        handleMaximizeChange();
        context.setLiveAgent(false);
        context.setShowHeaderFooter(false);
        sessionStorage.removeItem("chatbotActive");
        sessionStorage.removeItem(window.config.businessUnit + '-SatmatrixSurveyOpen');
        window.history.go(0);
      } else {
        showModal(isMaximised && actionItem.isOpenModal);
      }
    },
    [isMaximised, isSurveyOpen, showModal, showSurvey, handleSurveyCardChange, callbackFormUrl,setSurveyLink,setSatmatrixSurvey,setSatmatrixSurveyIFrame,setLiveAgent]
  );

  
  // async function openInNewTab(href){
  //   const anchorDoc=Object.assign(document.createElement('a'),{});
  //   return anchorDoc;
  // }
  async function cancelSession() {
    // const agentSessionId = sessionStorage.getItem("AgentSessionID");
    // if (agentSessionId != null) {
    //   const response = await getAgentToken(agentSessionId);
    //   return response;
    // }
    if(isAgentInitiated)
    {
      if(store!=null)
      {
        store.dispatch({
          type: "WEB_CHAT/SEND_EVENT",
          payload: {
            name: "CancelSession"
          }
        });
      }  
    } 
    }

  async function getDirectLineToken() {
    const res = await getToken();
    return (res.data && res.data.token) ? res.data : null;
  }

  async function getRefreshDirectLineToken() {
    const res = await getRefreshToken();
    if(res.data && res.data.token) {
       setCookie(window.config.businessUnit + '-Token',res.data.token);
    }
    else {
      removeDLCookies(false);
    }
  }

  let isRejectedActivity = false;
  if (!store) {
    setStore(
      window.WebChat.createStore({}, ({ dispatch }) => next => async action => {
        setTimeout(() => {
          if (document.getElementById("web-chat")) {
            document.getElementById(
              "web-chat"
            ).scrollTop = document.getElementById("web-chat").scrollHeight;
          }
        }, 0);
        if(action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
            console.log("Bot webchat/join:", Date.now());
            if(window.config.businessUnit === "HCE-MARKETING") {
                dispatch({
                    type: "WEB_CHAT/SEND_EVENT",
                    payload: {
                        name: "webchat/join",
                        value: {
                            language: window.navigator.language,
                            accessToken: getCWACookies("cwaSSOToken"),
                            sbg: window.config.sbg,
                            gbe: window.config.gbe,
                            portalName: window.config.portalName,
                            browserTime: new Date(),
                            userId: localStorage.getItem("DCAUserId"),
                            isNewSSO: false
                        }
                    }
                });
            } else {
               const portalName = (window.config.isAEM) ? window.config.portalNameAEM : window.config.portalName;

                dispatch({
                    type: "WEB_CHAT/SEND_EVENT",
                    payload: {
                        name: "webchat/join",
                        value: {
                            language: (window.config.businessUnit === 'HBT-GCEGBT' || window.config.businessUnit === 'SPS-ECOMM') ? window.config.language : window.navigator.language,
                            countrySelectedOnPortal: window.config.countrySelectedOnPortal,
                            accessToken: getCWACookies("cwaSSOToken"),
                            sbg: window.config.sbg,
                            gbe: window.config.gbe,
                            portalName: (window.config.businessUnit === 'HBT-GCEGBT' || window.config.businessUnit === 'SPS-ECOMM') ? portalName.replace('/en/','/' + window.config.language + '/') : portalName,
                            browserTime: new Date(),
                            pageTitle:  window.config.pageTitle,
                            portalPageUrl: window.config.portalPageUrl,
                            browserName: window.config.browserName,
                            isOldConversation: getCookie(window.config.businessUnit + '-OldCoversation') ? true : false,
                            encryptedSoldToAccount: window.config.b2bunit,
                            isAEM: window.config.isAEM,
                            isNewSSO: window.config.isNewSSO
                        }
                    }
                });
            }
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "customerInfoCompleted"
        ) {
          if(document.getElementById("spinner-text")){
            document.getElementById("spinner-text").innerHTML =
            window.config.businessUnit === "HCE-TRIDIUM" ? "Connecting to Tridium Assist" : "Connecting to Honeywell Assist";
          }
        }

        if(
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "typing" &&
          action.payload.activity.value === "marketing"
        ) {
            window.config.showTypingIndicator = true;

            setTimeout(() => {
                window.config.showTypingIndicator = false;
            }, action.payload.activity.delay);
        }

        if(
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "defaultWorkflowTaken"
        ) {
            window.config.isDefaultWorkflowClicked = true;
        }

        if(
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "initiateWorkflow"
        ) {
            if(!action.payload.activity.value.isReceived && !action.payload.activity.value.isDefaultWorkflow) {
                dispatch({
                    type: "WEB_CHAT/SEND_EVENT",
                    payload: {
                        name: "workflowEventReceived",
                        value: {
                            userId: localStorage.getItem("DCAUserId"),
                            workflowId: action.payload.activity.value.workflowId,
                            childWorkflowId: "",
                            pageRuleId: action.payload.activity.value.pageRuleId,
                            pageId: action.payload.activity.value.pageId,
                            priority: action.payload.activity.value.priority
                        }
                    }
                });
            }

           

            if((!window.config.isWorkflowTriggered) ||
                  (window.config.isWorkflowTriggered && !window.config.isWorkflowClicked && window.config.isDefaultWorkflow && 
                        !action.payload.activity.value.isDefaultWorkflow && action.payload.activity.value.workflowId)
              ) {
                window.config.isDefaultWorkflow = action.payload.activity.value.isDefaultWorkflow;
                window.config.isWorkflowTriggered = true;

               
                
                setTimeout(() => {
                    
                    
                    context.setEnableScroll(true);
                    context.setStartingMessage(action.payload.activity.value.startingMessage);
                    context.setWorkflowId(action.payload.activity.value.workflowId);
                    context.setPageRuleId(action.payload.activity.value.pageRuleId);
                    context.setPageId(action.payload.activity.value.pageId);
                    context.setPriority(action.payload.activity.value.priority);
                    
                    // if(!JSON.parse(sessionStorage.getItem('notification'))) {
                    //   //context.setLauncherIcon(false);
                      window.parent.postMessage({key:'minimise', launcherIcon: false }, window.config.pageOrigin);
                    //}
                }, ((action.payload.activity.value.triggerTime - 10) * 1000));
            }
        }
  
            if (
              action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
              action.payload.activity.type === "event" &&
              action.payload.activity.name === "openWebform"
            ) {
              if(getCookie(window.config.businessUnit + '-OldCoversation')){
               if(getCookie(window.config.businessUnit + '-botMaximiseTime')){
                  const date1=getCookie(window.config.businessUnit + '-botMaximiseTime');
                  var difference = new Date().getTime()-date1;
                  var minutesDifference = Math.floor(difference/1000/60);
                  difference -= minutesDifference*1000*60;
                  var secondsDifference = Math.floor(difference/1000);
                  if(secondsDifference>15){
                    window.open(action.payload.activity.value,"_blank");  
                  }
                }
               }
               else{
               window.open(action.payload.activity.value,"_blank");  
               }
          }
        if ( //To make webformpopup appear for LAC LOB selection Agent unavailable.
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "webFormPopupVisible"
        ) {
         context.setCaseWebFormArticleModal(action.payload.activity.value);
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "openWebformDynamically"
        ) {
          if(action.payload.activity.value.sametab){
            window.open(action.payload.activity.value.link,'Live Chat','toolbar=no,scrollbars=yes,resizable=yes,width=433,height=480'); return false;
          } else
          window.open(action.payload.activity.value.link,"_blank");
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "refreshAcknowledge"
        ) {
          removeDLCookies("sessiontimeout");
          handleRefreshModalPopup();
          setDirectLine(null);
          setStore(null);
          setDirectLineToken();
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "closeAcknowledge"
        ) {
          if(getCookie(window.config.businessUnit + '-isPrivacyAccepted') && window.config.isNewPrivacy==true) {
            eraseCookie(window.config.businessUnit + '-isPrivacyAccepted');
          }
          if(document.getElementById("root").classList.contains("root-max") && !sessionStorage.getItem(window.config.businessUnit + '-SatmatrixSurveyOpen')) {
            handleCloseModalPopup();
            handleCloseBotInTabs();
          }
          else if(!sessionStorage.getItem(window.config.businessUnit + '-SatmatrixSurveyOpen')) {
            window.history.go(0);
          }
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "removeSpinner"
        ) {
            setSpinner(false);
            const elements = document.querySelectorAll("#web-chat ul li");

            if(elements.length > 0) {
                elements.forEach((elem, index) => {
                  if(index === window.config.elementsCount) {
                      return;
                  }
                  elem.remove();
                });
            }
        }

        if(
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "marketingErrorException"
        ) {            
            dispatch({
                type: "WEB_CHAT/SEND_POST_BACK",
                payload: {
                    value: {
                        processName: "initiateMarketingWorkflow",
                        userId: localStorage.getItem("DCAUserId"),
                        startingMessage: context.startingMessage,
                        workflowId: context.workflowId,
                        pageRuleId: context.pageRuleId,
                        pageId: context.pageId,
                        priority: context.priority,
                        exceptionErrorFound: true
                    }
                }
            });
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "signin/success"
        ) {
          if(document.getElementById("spinner-text")){
          document.getElementById("spinner-text").innerHTML =
            "Gathering your information";
          }

          setSpinner(false);
          context.setMinimizeBot(false);
          if(action.payload.activity.value.status) {
              showErrorPage({ isErrorOccured: false, message: "" });
              if(window.config.isWebSocketConnect) {
                  let message = JSON.stringify({});

                  if(window.config.businessUnit === "HCE-MARKETING") {
                      message = JSON.stringify({"businessUnit": window.config.businessUnit, "userId": localStorage.getItem("DCAUserId"), "conversationId": action.payload.activity.conversation.id, "pageName": localStorage.getItem("pageUrl"), "eventFrom": window.config.eventFrom});
                      window.config.eventFrom = null;
                  } else {
                      message = JSON.stringify({"businessUnit": window.config.businessUnit, "userId": action.payload.activity.value.userid, "conversationId": action.payload.activity.conversation.id});
                     
                  }
                  if(window.config.socket) {
                      window.config.socket.send(message); 
                  } else {
                      window.config.socket = new WebSocket(window.config.webSocketUrl);
                      window.config.socket.addEventListener("open", function (event) {
                          window.config.socket.send(message); 
                          if(event.origin !== window.config.pageOrigin)
                            return;
                      });
                  }
              }
          } else {
            sessionStorage.removeItem("cwaSSOToken");
            if(getCookie(window.config.businessUnit + '-isPrivacyAccepted') && window.config.isNewPrivacy==true) {
            eraseCookie(window.config.businessUnit + '-isPrivacyAccepted');
            }
            showErrorPage({
              isErrorOccured: true,
              message: {
                text: "Login Failed",
                subText: "Your username and/or password do not match",
                link: {
                  text: "Retry",
                  src: (window.config.isAEM) ? `${window.config.portalUrlAEM}` : `${window.config.portalUrl}`,
                  target: "_parent"
                }
              }
            });
          }
        }
        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "getCredsDetails"
        ) {
          window.config.documentDownloadAPI=action.payload.activity.value.documentDownloadAPI;
          window.config.searchServiceApi=action.payload.activity.value.searchServiceApi;
        }
        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "feedback"
        ) {
            if(window.config.businessUnit === "HCE-TRIDIUM") {
                dispatch({ //Send CloseBot event
                    type: "WEB_CHAT/SEND_EVENT",
                    payload: {
                        name: "closeWithFeedback",
                        value: getCWACookies("cwaSSOToken")
                    }
                });
            } else if(action.payload.activity.value.checkShowOrHideSurvey) {
                context.setShowSurvey(action.payload.activity.value.sendSurvey);
                surveyPageShow.current = action.payload.activity.value.sendSurvey;

                if(surveyPageShow.current) {
                    handleSurveyCardChange();
                    setHeaderIconsDisplay(false);
                    context.setShowHeaderFooter(false);
                    context.setEnableScroll(false);
                } else {
                    showModal(true);
                    context.setModalProps({ refresh: false, close: true, quitAgentQueue: false, busyNotification: false });
                }
            }  else {
                handleSurveyCardChange();
                setHeaderIconsDisplay(false);
                context.setShowHeaderFooter(false);
                context.setEnableScroll(false);
            }
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "feedbackGoBackButtonAck"
        ) {
          showSurvey(false);
          context.setOptionSelected(true);
          context.setShowHeaderFooter(true);
          context.setEnableScroll(true);
          setHeaderIconsDisplay(true);
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "feedbackCancelButtonAck"
        ) {
          showModal(false);
          context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: false });
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "exitQueueButtonAck"
        ) {
          setShowTimerBox(false);
          context.setLiveAgent(false);
          context.setShowSendBox(true);
          context.setDisableSendBox(true);
          context.setEnableScroll(true);
          context.setHideStickyMenu(false);
        }

      if (
        action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
        action.payload.activity.type === "event" &&
        action.payload.activity.name === "updatedFeedback"
      ) {
        if(window.config.businessUnit === "HCE-TRIDIUM") {
          context.setShowSurvey(action.payload.activity.value);
          surveyPageShow.current = action.payload.activity.value;

          if (surveyPageShow.current) {
            handleSurveyCardChange();
            setHeaderIconsDisplay(false);
            context.setShowHeaderFooter(false);
            context.setEnableScroll(false);
          }
          else {
            showModal(true);
            context.setModalProps({ refresh: false, close: true, quitAgentQueue: false, busyNotification: false });
          }
        }
        else {
          handleSurveyCardChange();
          setHeaderIconsDisplay(false);
          context.setShowHeaderFooter(false);
          context.setEnableScroll(false);
        }
    }

      if(
        action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
        action.payload.activity.type === "event" &&
        action.payload.activity.name === "closeBot"
      ) {
            context.setMenuOpen(false);
            context.setMinimizeBot(false);
            
            sessionStorage.setItem('notification',false);
            sessionStorage.removeItem("chatbotActive");
            document.getElementById("root").removeAttribute("style");
            document.getElementById("root").classList.remove("root-max");
            document.getElementById("main").classList.remove("main-max");
            document.getElementById("main").classList.add("main-min");

            if(!JSON.parse(sessionStorage.getItem('notification'))) {
              window.parent.postMessage({key:'minimise', launcherIcon: false }, window.config.pageOrigin);
            }

            setMaximizeflag(isMaximised);

            if(window.config.businessUnit === "HCE-MARKETING") {
                window.config.elementsCount += document.querySelectorAll("#web-chat ul li").length;

                window.config.isDefaultWorkflow = false;
                window.config.isWorkflowTriggered = false;
                window.config.isWorkflowClicked = false;
                window.config.showTypingIndicator = true;

                context.setWorkflowId(null);
                context.setPageRuleId(null);
                context.setPageId(null);
                context.setPriority(null);

                dispatch({
                    type: "WEB_CHAT/SEND_EVENT",
                    payload: {
                        name: "triggerNextNotification",
                        value: {
                            userId: localStorage.getItem("DCAUserId"),
                            pageName: localStorage.getItem("pageUrl"),
                            eventFrom: "DCA"
                        }
                    }
                });
            }
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "refresh"
        ) {
          showModal(true);
          context.setModalProps({ refresh: true, close: false, quitAgentQueue: false, busyNotification: false });
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "userLanguage"
        ) {
          setUserLanguage(action.payload.activity.value);
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "requestcallback"
        ) {
          window.open(action.payload.activity.value, "_blank");
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "agentsNotAvailable"
        ) {
         context.setShowSendBox(true);
         setShowTimerBox(false);
         setAgentInitiated(false);
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "agentdisconnected"
        ) {
         // sessionStorage.removeItem("AgentSessionID");
          context.setShowSendBox(false);
          setAgentInitiated(false);
          context.setLiveAgent(false);
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "agentInitiated"
        ) {
          if(!window.config.isNewUI) {
            context.setShowSendBox(false);
            context.setHideStickyMenu(true);
            context.setDisableSendBox(false);
          }
          setAgentInitiated(true);
          setShowTimerBox(true);

          var callBackUrl ="";
          switch(window.config.sbg){
            case "AERO":
              callBackUrl = action.payload.activity.value.supportCategory === "Customer Support" ? window.config.customerSupportUrl: window.config.technicalSupportUrl;
              break;
            default:
              callBackUrl=window.config.customerSupportUrl;
            }

          setCallbackFormUrl(callBackUrl);
          
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "webformPopup"
        ) {
          context.setWebformContent(action.payload.activity.value.content);
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "agentDiffSupportCategory"
        ) {
         
          var callBackUrl ="";
          switch(window.config.sbg){
            case "AERO":
              callBackUrl = action.payload.activity.value.supportCategory === "Customer Support" ? window.config.customerSupportUrl: window.config.technicalSupportUrl;
              break;
            default:
              callBackUrl="";
            }

          setCallbackFormUrl(callBackUrl);
          
        }

        // if (
        //   action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
        //   action.payload.activity.type === "event" &&
        //   action.payload.activity.name === "showAgentTimerBox"
        // ) {
        //   context.setShowSendBox(false);
        //   context.setHideStickyMenu(true);
        //   context.setDisableSendBox(false);
        // }

         if (
           action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
           action.payload.activity.type === "event" &&
           action.payload.activity.name === "disableSendBox"
         ) {
           if(!context.isLiveAgent){
             context.setDisableSendBox(true);
           }
         }
         
         if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "enableTextBox"
        ) {
            context.setEnableSendBoxHBT(true);
        }
        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "modalSubmitCancelled"
        ) {
          context.setCaseWebFormArticleModal(false);
        }
        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "enableSendBox"
        ) {
          context.setDisableSendBox(false);
          setCookie(window.config.businessUnit + '-EnableSendBox', true);
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "enableSendBoxEventAcknowledged"
        ) {
          context.setDisableSendBox(true);
          setCookie(window.config.businessUnit + '-EnableSendBox', false);
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "enableRestrictionInTextbox"
        ) {
          context.setRestrictTextBox(true);
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "disableRestrictionInTextbox"
        ) {
          context.setRestrictTextBox(false);
        }
        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "viewOriginalLineItemsButtonId"
        ) {
          const viewOriginalLineItemsButtonId = action.payload.activity.value.viewOriginalLineItemsButtonId;
        }
        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "disableViewLineItemButton"
        ) {
          const viewLineItemsButtonId = action.payload.activity.value.viewLineItemsButtonId;
          if(document.getElementById(viewLineItemsButtonId)) {
            document.getElementById(viewLineItemsButtonId).disabled = true;
          }
        }
        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "disableConfirmLineItemButton"
        ) {
          let submitObject = {
            id: action.payload.activity.value.confirmLineItemsButtonId,
           ...action.payload.activity.value.geographyValue
          }
          let checkHistory = getCookie(window.config.businessUnit + '-CheckOrderLob');
          if(checkHistory){
          checkHistory=JSON.parse(checkHistory);
          if (Array.isArray(checkHistory)) {
            if (!checkHistory.map(i => i.id).includes(submitObject.id)) {
            setCookie(window.config.businessUnit + '-CheckOrderLob', JSON.stringify([...checkHistory, submitObject]))
            }
          } 
          }  
          else {
            setCookie(window.config.businessUnit + '-CheckOrderLob', JSON.stringify([submitObject])) 
          }
          const confirmLineItemsButtonId = action.payload.activity.value.confirmLineItemsButtonId;
          if(document.getElementById(confirmLineItemsButtonId)) {
          document.getElementById(confirmLineItemsButtonId).disabled = true;
          }
          }
          if (
            action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
            action.payload.activity.type === "event" &&
            action.payload.activity.name === "disableViewOriginalLineItemButton"
          ) {
            const viewOriginalLineItemsButtonId = action.payload.activity.value;
            setTimeout(()=>{
              if(document.getElementById(viewOriginalLineItemsButtonId)) {
               document.getElementById(viewOriginalLineItemsButtonId).style.display = "none";
              }
            },500);           
            }
        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "disableQnaSuggestionList"
        ) {
            const qnaSuggestionList = document.getElementsByClassName(action.payload.activity.value.qnaSuggestionListClassName);

            if(qnaSuggestionList.length > 0) {
                for(let i = 0; i < qnaSuggestionList.length; i++) {
                    qnaSuggestionList[i].disabled = true;
                }
            }
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          (action.payload.activity.name === "agentjoined" || action.payload.activity.name === "agenttransfered")
        ) {
          context.setLiveAgentName(action.payload.activity.value.agentname);
          context.setShowSendBox(true);
          context.setHideStickyMenu(true);
          context.setDisableSendBox(false);
          setShowTimerBox(false);
          context.setLiveAgent(true);
          showModal(false);
        }
        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          (action.payload.activity.name === "languageUserSelected")
        ) {
          context.setLiveAgentDropdownLanguagePreferred(action.payload.activity.value.liveAgentPreferredLanguage);
        }
        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          (action.payload.activity.name === "agentJoinedTransfer")
        ) {
          context.setTranscriptTranslationRequired(action.payload.activity.value.transcriptTranslationRequired);
        }
        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "SendTriggerSurvey"
        ) {
          context.setShowSurvey(true);
          surveyPageShow.current = true;
          dispatch({
            type: "WEB_CHAT/SEND_EVENT",
            payload: {
              name: "GetSurveyLink"
            }
          });
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "hideStickyMenu"
        ) {
          context.setHideStickyMenu(true);
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "displayStickyMenu"
        ) {
          context.setHideStickyMenu(false);
        }

        if(action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "GetGreeting"
        ) {
            const currentTime = new Date();
            let greetingMessage = "";

            if(currentTime.getHours() < 12) {
                greetingMessage = "Good Morning";
            } else if(currentTime.getHours() < 17) {
                greetingMessage = "Good Afternoon";
            } else {
                greetingMessage = "Good Evening";
            }

            dispatch({
                type: "WEB_CHAT/SEND_EVENT",
                payload: {
                    name: "SendGreeting",
                    value: greetingMessage
                }
            });
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "agentqueuecount"
        ) {
          if(!window.config.isNewUI) {
            context.setShowSendBox(false);
          }
          setShowTimerBox(true);
          context.setUsersCount(action.payload.activity.value);
        }

        // if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
        //   action.payload.activity.type === "event" &&
        //   action.payload.activity.name === "agentcallbackform") {
        //     // if(isOpenModal){
        //     //    showModal(true);
        //     // }
        //     showModal(true);
        //     context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: true });
        //   if (action.payload.activity.value === "GTO") {
        //     setCallbackFormUrl("https://aerospace.stage.honeywell.com/en/secure/contact-us/forms/technical-issue/commercial-new");
        //   }
        //   if (action.payload.activity.value === "GCE") {
        //     setCallbackFormUrl("https://aerospace.stage.honeywell.com/en/contact-us");
        //   }
        // }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "SatmetrixSurveyLink"
        ) {
           
           if(satmatrixSurvey)
           setSatmatrixSurveyIFrame(true);
           setSurveyLink(action.payload.activity.value);

          // window.open(
          //   action.payload.activity.value,
          //   "targetWindow",
          //   "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600"
          // );
        }

        if (
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          isRejectedActivity
        ) {
          isRejectedActivity = false;
          if (document.getElementsByClassName("sendRetry").length != 0) {
            Array.prototype.forEach.call(
              document.getElementsByClassName("sendRetry"),
              function (element) {
                element.style.visibility = "hidden";
              }
            );
            Array.prototype.forEach.call(
              document.getElementsByClassName("sendRetry"),
              function (element) {
                element.style.display = "none";
              }
            );
          }
        }

        if (
          action.type === "DIRECT_LINE/POST_ACTIVITY_REJECTED" &&
          action.error
        ) {
          if (action.payload.message.indexOf("cancelled timeout") !== -1) {
            showErrorPage({
              isErrorOccured: true,
              message: {
                text: "Network Error",
                subText: "The network connection is lost",
                link: {
                  text: "Please Contact Customer Support",
                  src: window.config.supportUrl,
                  target: "_blank"
                }
              }
            });
            setSpinner(false);
          } else {
            isRejectedActivity = true;
           

            //  dispatch({
            //     type: 'WEB_CHAT/SEND_POST_BACK',
            //    payload:{value:sessionStorage.getItem("UserLastMessage")}

            //   });
            if (document.getElementsByClassName("sendRetry").length != 0) {
              document.getElementsByClassName("sendRetry")[
                document.getElementsByClassName("sendRetry").length - 1
              ].style.visibility = "visible";
              document.getElementsByClassName("sendRetry")[
                document.getElementsByClassName("sendRetry").length - 1
              ].style.display = "block";
            }
          }
        }

        if(action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "accountChangedPopup"
        ) {
            showModal(true);
            context.setIsAccountChanged(true)
            context.setModalProps({ refresh: false, close: false, quitAgentQueue: false, busyNotification: true});
        }
        try {
          let result = next(action);
          return result;
        } catch (err) {
        }
      }),
      [
        showSpinner,
        accessToken,
        handleSpinnerChange,
        setSpinner,
        handleModalChange,
        setSurveyLink,
        setSatmatrixSurveyIFrame
      ]
    );
  }

  return (
    isDisplay && (
    <TelemetryProvider
      style={{ height: "100%" }}
      instrumentationKey={(window.config.businessUnit === 'PMT-HPS' || window.config.businessUnit === 'SPS-ECOMM') ? (window.config["4469726563746c696e650k0b"]).split("&=")[1] : window.config.applicationInsightsKey}
      after={() => {
        setAppInsights(getAppInsights());
        if(window.config.businessUnit === "HCE-MARKETING" && !directLine) {
            // check and set DCAUserId Cookie
            if(!localStorage.getItem("DCAUserId")) {
                localStorage.setItem("DCAUserId", uuidv4());
            }
            // We will load DirectLineJS asynchronously on first render.
            setDirectLineToken();
        }
      }}
    >
      <React.Fragment>
        <div className="main" id="main">
          <Header
            isMaximised={isMaximised}
            store={store}
            directLine={directLine}
            handleRefreshModalPopup={handleRefreshModalPopup}
            handleCloseModalPopup = {handleCloseModalPopup}
            handleMaximizeChange={handleMaximizeChange}
            handleModalChange={handleModalChange}
            handleSurveyCardChange={handleSurveyCardChange}
            isOpenModal={isOpenModal}
            isLiveAgent={isLiveAgent}
            userLanguage={userLanguage}
            headerIconsDisplay={headerIconsDisplay}
            surveyLink={surveyLink}
            isSurveyOpen={isSurveyOpen}
          />
          {accessToken && !errorOccured.isErrorOccured ? (
          // !satmatrixSurveyIFrame ? 
            <MainWebChat
              showSpinner={showSpinner}
              isMaximised={isMaximised}
              isSurveyOpen={isSurveyOpen}
              userLanguage={userLanguage}
              handleMaximizeChange={handleMaximizeChange}
              handleCloseModalPopup = {handleCloseModalPopup}
              handleSurveyCardChange={handleSurveyCardChange}
              directLine={directLine}
              store={store}
              isLiveAgent={isLiveAgent}
              handleModalChange={handleModalChange}
              styleSet={styleSet({})}
              appInsights={appInsights}
              showTimerBox={showTimerBox}
              setShowTimerBox={setShowTimerBox}
              handleSpinnerChange={handleSpinnerChange}
              showSurvey={showSurvey}
              setHeaderIconsDisplay={setHeaderIconsDisplay}
              surveyLink={surveyLink}
              setSatmatrixSurvey={setSatmatrixSurvey}
              setSatmatrixSurveyIFrame={setSatmatrixSurveyIFrame}
              satmatrixSurveyIFrame={satmatrixSurveyIFrame}
              isAgentInitiated={isAgentInitiated}
              setLiveAgent={setLiveAgent}
              setDirectLineTokenCall={ setDirectLineTokenCall}
            />
          ) : (
            !errorOccured.isErrorOccured && (
              <ErrorHandler
                isMaximised={isMaximised}
                message={{
                  text: "Access Denied",
                  subText: "You are not authorized to use this application.",
                  link: {
                    text: "Please Contact Customer Support",
                    src: window.config.supportUrl,
                    target: "_blank"
                  }
                }}
              />
            )
          )}
          {errorOccured.isErrorOccured ? (
            <ErrorHandler
              isMaximised={isMaximised}
              message={errorOccured.message}
            />
          ) : (
            ""
          )}
        <Footer isMaximised={isMaximised} />
        </div>
      </React.Fragment>
    </TelemetryProvider>
    )
  );
};
